<template lang="pug">
InputGroupControl(
  :label="$t('margin')"
  type="margin"
  :customProperty="customProperty"
  :editMobile="showDeviceSelector"
)
</template>
<script>
  import InputGroupControl from './InputGroup.vue';

  export default {
    components: { InputGroupControl },
    props: {
      customProperty: {
        type: String,
        default: null,
      },
      showDeviceSelector: {
        type: Boolean,
        default: true,
      },
    },
  };
</script>
