<template lang="pug">
InputGroup(
  :label="label"
  v-model="internalValue"
  :disabled="disabled"
  :placeholders="placeholders"
  :allSides.sync="internalAllSides"
  :editMobile="editMobile"
)
</template>

<script>
  import { mapState } from 'vuex';
  import itemMixin from '@/editor/mixins/item';
  import InputGroup from '@/components/Editor/InputGroup/InputGroup.vue';

  const KEY_ALL_SIDES = 'allSides';
  const KEYS = ['top', 'right', 'bottom', 'left'];

  export default {
    components: { InputGroup },
    mixins: [itemMixin],
    props: {
      type: {
        type: String,
        required: true,
        validate(value) {
          return ['margin', 'padding'].includes(value);
        },
      },
      label: {
        type: String,
        default: null,
      },
      disabled: {
        type: Array,
        default: () => [false, false, false, false],
      },
      allowNegative: {
        type: Boolean,
        default: true,
      },
      subElements: {
        type: Boolean,
        default: false,
      },
      customProperty: {
        type: String,
        default: null,
      },
      editMobile: {
        type: Boolean,
        default: true,
      },
    },
    data: () => ({
      canEditMobile: true,
    }),
    computed: {
      ...mapState(['mobilePreview']),
      placeholders() {
        if (this.mobilePreview) {
          return KEYS.map((key) => this.getValueOf(this.getPath(key, 'desktop')));
        }

        return undefined;
      },
      internalValue: {
        get() {
          return KEYS.map((key) => this.getValueOf(this.getPath(key)));
        },
        set(value) {
          KEYS.forEach((key, index) => {
            this.setValueOf(this.getPath(key), value[index]);
          });
        },
      },
      internalAllSides: {
        get() {
          return this.getValueOf(this.getPath(KEY_ALL_SIDES));
        },
        set(value) {
          const path = this.getPath(KEY_ALL_SIDES);
          this.setValueOf(path, value);
          if (value) this.setSameValues(0);
        },
      },
    },
    methods: {
      getPath(property, device = '$device') {
        const pieces = [device, this.type, property];

        if (this.customProperty) {
          return this.customProperty.concat(`.${property}`);
        }

        if (this.subElements) {
          pieces.unshift('subElements');
        }

        return pieces.join('.');
      },
      setSameValues(index) {
        this.internalValue = KEYS.map(() => this.internalValue[index]);
      },
    },
  };
</script>
